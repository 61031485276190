// @ts-nocheck

import React, { BaseSyntheticEvent, Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Tab, Tabs } from '@material-ui/core'
import EmployeeCompanyMyCurriculumsView from './EmployeeCompanyMyCurriculumsView/EmployeeCompanyMyCurriculumsView'
import EmployeeCompanyMyBudgetView from './EmployeeCompanyMyBudgetView/EmployeeCompanyMyBudgetView'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import KnowledgeCentreView from './KnowledgeCentreView/KnowledgeCentreView'
import EventsView from './EventsView/EventsView'
import { showDemo } from '../../../helpers/env'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps<{ tab: string }> {}

interface StatesI {}

// const TABS = ['my-curriculum', 'my-budget', 'knowledge-centre', 'events']

class EmployeeCompanyPage extends Component<PropsI, StatesI, any> {
  constructor(props: PropsI) {
    super(props)

    this.state = {
      currentTab: 'my-curriculum',
    }

    // this.onTabChange = this.onTabChange.bind(this)
  }

  componentDidMount() {}

  // onTabChange(event: BaseSyntheticEvent, tabIndex: number) {
  //   const { history } = this.props
  //   switch (tabIndex) {
  //     case 0:
  //       history.push(`/employee/company/${TABS[0]}`)
  //       break

  //     case 1:
  //       history.push(`/employee/company/${TABS[1]}`)
  //       break

  //     case 2:
  //       history.push(`/employee/company/${TABS[2]}`)
  //       break

  //     case 3:
  //       history.push(`/employee/company/${TABS[3]}`)
  //       break
  //   }
  // }

  // getIndexByParams(tab: string) {
  //   switch (tab) {
  //     case TABS[0]:
  //       return 0

  //     case TABS[1]:
  //       return 1

  //     case TABS[2]:
  //       return 2

  //     case TABS[3]:
  //       return 3

  //     default:
  //       return 0
  //   }
  // }

  renderComponentByTab(tab: string) {
    switch (tab) {
      case 'my-curriculum':
        return <EmployeeCompanyMyCurriculumsView />

      case 'knowledge-centre':
        return <KnowledgeCentreView />

      case 'events':
        return <EventsView />

      case 'my-budget':
        return <EmployeeCompanyMyBudgetView />

      default:
        return <EmployeeCompanyMyCurriculumsView />
    }
  }

  render() {
    const { currentCompany, match, history } = this.props

    // const selectedTabIndex = this.getIndexByParams(match.params.tab)
    return (
      <MainDashboardWrapper
        title={`V-Client Company (${currentCompany?.name})`}
        page="client-company"
      >
        <Tabs
          value={match.params.tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => {
            history.push(`/employee/company/${value}`)
            this.setState({ currentTab: value })
          }}
          // aria-label="disabled tabs example"
        >
          <Tab value={'my-curriculum'} label="My Curriculums" />
          {showDemo && <Tab value={'knowledge-centre'} label="Knowledge Centre" />}
          {showDemo && <Tab value={'events'} label="Events / Webinars" />}
          <Tab value={'my-budget'} label="My Budget" />
        </Tabs>
        {this.renderComponentByTab(match.params.tab)}
      </MainDashboardWrapper>
    )
  }
}

interface MapStatePropsI {
  currentCompany: any
}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    currentCompany: state.user.currentCompany,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeCompanyPage))
